.Logo {
  position: relative;
  display: inline-block;
}

.Logo-react {
  -webkit-animation: Logo-spin infinite 10s linear;
          animation: Logo-spin infinite 10s linear;
  height: 100%;
}

.Logo-kotlin {
  opacity: 0;
  -webkit-animation: Logo-spin infinite 10s 5s linear;
          animation: Logo-spin infinite 10s 5s linear;
  height: 72%;
  position: absolute;
  top: 14%;
  right: 24%;
}

@-webkit-keyframes Logo-spin {
  from { -webkit-transform: rotateY(-90deg); transform: rotateY(-90deg); opacity: 1 }
  50% { -webkit-transform: rotateY(90deg); transform: rotateY(90deg); opacity: 1 }
  51% { -webkit-transform: rotateY(90deg); transform: rotateY(90deg); opacity: 0 }
  to { -webkit-transform: rotateY(270deg); transform: rotateY(270deg); opacity: 0 }
}

@keyframes Logo-spin {
  from { -webkit-transform: rotateY(-90deg); transform: rotateY(-90deg); opacity: 1 }
  50% { -webkit-transform: rotateY(90deg); transform: rotateY(90deg); opacity: 1 }
  51% { -webkit-transform: rotateY(90deg); transform: rotateY(90deg); opacity: 0 }
  to { -webkit-transform: rotateY(270deg); transform: rotateY(270deg); opacity: 0 }
}
