body {
  text-align: center;
}

.App-header {
  background-color: #000;
  height: 160px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.App-ticker {
  font-size: medium;
}
